export default {
    list: {
        title: "shippings",
        name: 'shippings',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                method: true,
                name: "Actions",
                buttons: [
                    {
                        text: "change_status",
                        icon: "update",
                        action:"changestatus",
                        color: 'primary',
                        class:''
                    },
                    {
                        text: "print_list",
                        icon: "print",
                        action:"print",
                        color: 'primary',
                        class:''
                    },
                    {
                        text: "print_delivry",
                        icon: "print",
                        action:"printdDelivry",
                        color: 'primary',
                        class:''
                    },
            ],
            }
        },
        columns: [
            {
             type:"checkbox",
             elements: [{
                items: [{
                    class: "",
                    type: "checkbox",
                }, ],
            }, ]
            },
            {
                name: "order_id",
                elements: [{
                    items: [{
                        class: "",
                        name: "order_id",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "source",
                elements: [{
                    items: [{
                        class: "",
                        name: "source",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "shipping_date",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "traking_number",
                elements: [{
                    items: [{
                        class: "",
                        name: "trackingNumber",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "Order", //order
                model: "shippings",
                title: 'products'
            },
            {
                name: "courier",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "courier",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-bold",
                                icone: "contacts"
                            }]
                        }]
                    }],
                }, ]
            },
            {
                name: "city",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "order",
                        elements: [{
                            items: [{
                                name: "city",
                                class: "font-bold",
                                icone: "map"
                            }]
                        }]
                    }],
                }, ]
            },
           
            {
                name: "type",
                elements: [{
                    items: [{
                        class: "",
                        name: "type",
                        options: (item) => {
                            switch (item) {
                                case 'normal':
                                    return "color: rgb(233 147 19);"
                                    break;
                                case 'change':
                                    return "color:rgb(8, 194, 226)"
                                    break;
                                case 'pendingchange':
                                    return "color:rgb(106 71 9)"
                                    break;
                            }
                        },
                    }, ],
                }, ]
            },
            {
                name: "status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'to prepare':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                    case 'outofstock':
                                        return "color: rgb(203 21 160); border-color: rgb(203 21 160);"
                                        break; 
                                case 'prepared':
                                    return "color:rgb(8, 194, 226); border-color: rgb(8, 194, 226);"
                                    break;
                                case 'shipped':
                                    return "color:rgb(106 71 9); border-color: rgb(106 71 9);"
                                    break;
                                case 'delivered':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break;
                                case 'paid':
                                    return "color:rgb(194, 168, 27); border-color: rgb(194, 168, 27);"
                                    break;
                                case 'processed':
                                    return "color:rgb(215, 26, 230); border-color: rgb(215, 26, 230);"
                                    break;            
                                case 'cancelled':
                                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                    break;
                                    case 'refused':
                                        return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                        break;
                                case 'remind':
                                    return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                                    break;    
                                case 'unreachable':
                                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                                    break;
                                    case 'pending return':
                                        return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                                        break;    
                                    case 'return':
                                        return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                                        break;    
                            }
                        },
                    }],
                }, ]
            },
        ],
        columnsDialog: {
            details: 'order',
            name: "details",
            elements: [
                // {
                //     class: "",
                //     name: "order_id",
                //     referent: "order",
                //     items: [{
                //         name: "id",
                //     }, ]
                // },
                {
                    class: "",
                    name: "seller",
                    referent: "order",
                    attribut: 'seller',
                    items: [{
                        class: "",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                    class: "font-bold text-gray-700",
                                    icone: "contacts"
                                },
                                // {
                                //     name: "email",
                                //     class: "font-light text-gray-700",
                                //     icone: "email"
                                // }
                            ]
                        }]
                    }, ]
                },
                {
                    class: "",
                    name: "customer",
                    referent: "order",
                    attribut: 'customer',
                    items: [{
                        class: "",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                    class: "font-bold",
                                    icone: "contacts"
                                },
                                {
                                    name: "phone",
                                    class: "font-light text-gray-700",
                                    icone: "local_phone"
                                }
                            ]
                        }]
                    }, ]
                },
                {
                    class: "",
                    name: "shipping_address",
                    referent: "order",
                    attribut: 'customer',
                    items: [{
                        name: "shippingAddress",
                    }, ]
                },
                {
                    class: "",
                    name: "total_price",
                    referent: "order",
                    items: [{
                        name: "totalPrice",
                    }, ]
                },
                // {
                //     class: "",
                //     name: "TOTAL Fees",
                //     referent: "order",
                //     items: [{
                //         name: "totalFees",
                //     }, ]
                // },
            ],
            sousElements: [{
                    class: "",
                    name: "image",
                    referent: "product",
                    items: [{
                        name: "picture",
                    }, ]
                },
                {
                    class: "",
                    name: "name",
                    referent: "product",
                    items: [{
                        name: "name",
                    }, ]
                },
                // {
                //     class: "",
                //     name: "code",
                //     referent: "product",
                //     items: [{
                //         name: "code",
                //     }, ]
                // },
                {
                    class: "",
                    name: "unit_price",
                    items: [{
                        name: "unitPrice",
                    }, ]
                },
                {
                    class: "",
                    name: "quantity",
                    items: [{
                        name: "quantity",
                    }, ]
                },
            ]
        },
        actions: {
            name: "actions",
            width: "10px",
            class: "justify-end",
            elements: [
                // {
                //     icone: "print",
                //     color: "success",
                //     key: 'print',
                //     role: true,
                //     id: "",
                //     tooltip: "Print",
                // },
                {
                    icone: "remove_red_eye",
                    color: "success",
                    key: 'read',
                    id: "",
                    role: true,
                    link: '/shippings/view',
                    tooltip: "Update",
                },
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/shippings/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'zones',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_shipping",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "status",
                        key: "status",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                
                     {
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "product",
                        key: "product",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "courier",
                        key: "courier",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "city",
                        key: "city",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "shipping_date",
                        key: "date",
                        value: null,
                        placeholder: "shipping_date",
                        type: "date",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}