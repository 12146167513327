<template>
  <div class="p-1">
     <div-table :config="configList" @Changelimit="Changelimit" :idWharhouse="idWharhouse" :currentUser="currentUser"
      @searchSellers="searchSellers" :couriers="couriers" :limit="limit" @searchCouriers="searchCouriers" 
      :isFilterCourier="isFilterCourier" :statusShippChange="statusShippChange"  :sous_warehouses="sous_warehouses" :sous_idWharhouse="sous_idWharhouse"
      :CourierInfo="CourierInfo" @downloadShippings="downloadShippings"
       @emtyTabId="emtyTabId" @selectAllCheckbox="selectAllCheckbox" @getRowDetail="getRowDetail" :tabId="tabId" :CheckQteDefecteuseexist="CheckQteDefecteuseexist" :CheckQteReturnedexist="CheckQteReturnedexist" :data="shippings" @CheckQteDefecteuse="CheckQteDefecteuse" @CheckQteReturned="CheckQteReturned" @reset="reset" :element="element" :zones="zones" :typesShipp="typesShipp" :statusShipp="statusShipp"  @refresh="refresh"
        @filtrer="filtrer" @search="search" :loading="loading" :paginate="paginate" @showProducts="showProducts"  :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist"  @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder" @generateListPdf="generateListPdf" @paginatation="paginatation"></div-table>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import config from './config'
import configMA from './configMA'
import image from "@/assets/edco_logo.png";
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
     sous_idWharhouse: { type: String},
     sous_warehouses: { type: Array},
   },
  data() {
    return {
       configList: config.list,
       loading:false,
       CheckQteDefecteuseexist:false,
       CheckQteReturnedexist:false,
       CheckQteDefecteuseorderexist:false,
       content: {},
       shippings:[],
       tabId:[],
       image:"https://storeno.b-cdn.net/rmexpressglob/logo.png",
       couriers: [],
       shippingsStockManager:[],
       shippingsLivreur:[],
       filters:{},
       element: {},
       //currentUser: {},
       limit: 16,
       sellers: [],
       paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
       },
       CourierInfo:{
         courier:null,
         total:0,
         count:0
       },
      tabIdList:[],
      isFilterCourier:false,
      statusShippSearch: ["to prepare","outofstock", "prepared", "shipped",'reprogrammer','rembourser','delivered','paid','processed','cancelled','refused','remind','unreachable','return'],
      statusShipp: ["to prepare","outofstock", "prepared", "shipped","reprogrammer",'rembourser','delivered','paid','processed','cancelled','refused','remind','unreachable','return'],
      statusShippChange:["to prepare","outofstock","prepared", "shipped",'delivered','paid',"refused","cancelled"],
      typesShipp:["pendingchange", "change"],
      zones:[],
      phoneED:"",
      //idWharhouse:null,
      cities:[],
      products:[]
    };
  },
  async mounted() {
    // console.log('satatu get-------------- 00',this.currentUser)
    // console.log('satatu get-------------- 11',this.idWharhouse)
    if(this.currentUser && this.currentUser.type && this.idWharhouse){
      if(['Seller','StockManager','Livreur','Return'].indexOf(this.currentUser.type) != -1) this.limit=10;
      //console.log('satatu get-------------- 22',this.idWharhouse)
          //await this.getUser();
        //await this.getwharhouseId();
        await this.getSettings();
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        else {
          let filtersobjectToQueryString={limit:this.limit,country:await this.warhouseSelected};
          if(this.sous_idWharhouse && this.sous_warehouses.length>0 && this.sous_idWharhouse!='all') filtersobjectToQueryString['orderObjet.details.warehouseParent']=this.sous_idWharhouse;
          this.$f.objectToQueryString(filtersobjectToQueryString)
        }
        
        this.filters=this.$f.QueryStringToobject()

        if(this.filters.name) await this.search(this.filters.name);
        else await this.getShipping(this.filters);
        //this.getShippingStockManager();
        //this.getShippingLivreur();
        //this.filters[0].values = this.categories;
        
        
        this.setDataSelect('category',this.categories,null); 

      
            if (this.currentUser.type == "Seller") {
              let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('seller');
              if(pos!=-1)  this.configList.columns.splice(pos, 1);  
              let posCourier = this.configList.columns.map(function (e) {return e.name;}).indexOf('courier');
              let poszone = this.configList.columns.map(function (e) {return e.name;}).indexOf('zone');
              if(posCourier!=-1)  this.configList.columns.splice(posCourier, 1);   
              if(poszone!=-1)  this.configList.columns.splice(poszone, 1);    
              let pos_1 = this.configList.columns.map(function (e) {return e.type;}).indexOf('checkbox');
              if(pos_1!=-1)  this.configList.columns.splice(pos_1, 1);            
            }
            
            if(this.currentUser.type == "StockManager") this.statusShippSearch=["to prepare","outofstock","prepared",'refused','cancelled']
            if(this.currentUser.type == "Livreur") this.statusShippSearch=["prepared",'shipped',"delivered",'to pick',"picked",'refused','cancelled']
            if(this.currentUser.type == "Accountant") this.statusShippSearch=["delivered",'paid']
            if(this.currentUser.type == "Return") this.statusShippSearch=["cancelled","refused",'shipped']

            await this.methodeChangeData();
            this.setDataSelect('status',this.statusShippSearch,null);  

            if (this.currentUser.type != "Admin" || this.currentUser.type != "CEO") {
              if (this.currentUser.type === "StockManager") {
                  let indexss = this.statusShippChange.indexOf("delivered");
                  if (indexss > -1) this.statusShippChange.splice(indexss, 1);
                    let indexp = this.statusShippChange.indexOf("paid");
                  if (indexp > -1) this.statusShippChange.splice(indexp, 1);
              }
          }
    }
  },
  computed: {
          warhouseSelected(){
            return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
          },
    },
    watch: {
        'warhouseSelected': async function(oldVal, newVal) {
            // await this.getwharhouseId();
           
        },
        'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
             await this.getSettings();
             await this.reset();
             await this.methodeChangeData();
             this.isFilterCourier=false;
        },
        'sous_idWharhouse': async function(oldVal, newVal) {
          this.sous_idWharhouse=oldVal;  
          await this.reset();
        }
    },
  filters: {},
  methods: {  
    async getSettings() {
        if(this.currentUser.type!="Seller"){
          const dataWahrhouse = await this.$server.get("settings",{warehouse:this.idWharhouse._id});
          if(dataWahrhouse.content.length>0){
              const currentSettingW=this.getElementInArray(dataWahrhouse.content[0].details,this.idWharhouse._id);
              if(currentSettingW && currentSettingW.phone) this.phoneED=currentSettingW.phone;
          }
        }
    },
    async getwharhouseId(){
      await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
          this.idWharhouse=res;
      })
    },
     getElementInArray(data,value){
        if(value){
          var result  = data.filter(function(o){
                let whar=o.warehouse;
                if(o.warehouse._id) whar=o.warehouse._id;
                return whar == value.toString();
            } );
            return result? result[0] : null; // or undefined
        }else  return null; 
    },
    async getCities(country){
      const resCities = await this.$server.get("zones", { allCities: true,country:country}); 
            if(resCities.content.length>0)  this.cities= resCities.content;
            else this.cities=[];    
    },
     async methodeChangeData(){
      
      if (this.currentUser.type != "Seller") { 
         await this.getCouriers();  await this.getZones(); await this.getSellers();  
         await this.getProducts();
         this.setDataSelect('seller',this.sellers,null); 
         this.setDataSelect('product',this.products,null); 
      }

       this.setDataSelect("courier", this.couriers, null);
       this.setDataSelect('zone',this.zones,null); 
       
      if (await this.warhouseSelected === 'MA') {
          await this.getCities('MA');
          this.setDataSelect('city',this.cities,null); 
          let pos1 = this.configList.columns.map(function (e) {return e.name;}).indexOf('zone');
          if(pos1!=-1)  this.configList.columns.splice(pos1, 1);  
        }else{
             let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('city');
             if(pos!=-1)  this.configList.columns.splice(pos, 1);  
        }
     },
      async CheckQteDefecteuseOrder(item){
       this.CheckQteDefecteuseorderexist=false;
       if(item.status == "return" ){
             for (let i in item.order.details) {
                 const filters = {
                   product: item.order.details[i].product._id,
                   order: item.order._id,
                   shipping:item._id
                 };
               const res = await this.$server.search("stockHistories", filters);
              
               if(typeof res.content.results !== 'undefined') {
                   item.order.details[i].product.qteChange=res.content.results[0].quantity;
                   this.CheckQteDefecteuseorderexist=true;
               }  
             }
       }    
     }, 
    async Changelimit(val){
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.filters['limit']=val;
       this.filters['page']=1;
       this.paginate.currentpage=1;
       this.$f.objectToQueryString(this.filters)
       if(this.filters.name) await this.search(this.filters.name);
       else await this.getShipping(this.filters);
    },
    sumQteProduct(data){
      let sum=0;
      for (let i in data) {
        sum=sum+data[i].quantity;
      }
      return sum;
    },
     sumProduct(data){
      let product="";
      for (let i in data) {
        if(data[i].product && data[i].product.name) product+=data[i].product.name
        if(data.length-1 != i) product+=" ,"; 
      }
      return product;
    },
      async downloadShippings(){
        var arr = typeof this.shippings !== 'object' ? JSON.parse(this.shippings) : this.shippings;
        for (let i in arr) {
              arr[i].ShippingAddress=arr[i].address?arr[i].address:'';
              arr[i].ShippingDate=this.$moment(arr[i].date).format("yyyy-MM-DD HH:mm");
              arr[i].FullName=arr[i].order.customer.fullName;
              arr[i].Phone=arr[i].order.customer.phone;
              arr[i].Phone2=arr[i].order.customer.shippingPhone?arr[i].order.customer.shippingPhone:'';
              arr[i].Order = this.sumProduct(arr[i].order.details);
              arr[i].TrakingNumber=arr[i].trackingNumber;
              arr[i].Status=arr[i].status;
        }
        for (let j in arr) {
              delete arr[j].courier;
              delete arr[j].createdAt;
              delete arr[j].address;
              delete arr[j].date;
              delete arr[j].isDeleted;
              delete arr[j].order;
              delete arr[j].price;
              delete arr[j].seller;
              delete arr[j].status;
              delete arr[j].trackingNumber;
              delete arr[j]._id;
              delete arr[j].type;
              delete arr[j].zone;
               delete arr[j].__v;
          }
          //console.log('orders rr______',arr)
        let str =
          `${Object.keys(arr[0])
            .map((value) => `"${value}"`)
            .join(',')}` + '\r\n';
        let csvContent = arr.reduce((st, next) => {
          st +=
            `${Object.values(next)
              .map((value) => `"${value}"`)
              .join(',')}` + '\r\n';
          return st;
        }, str);
        let element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'shippings.csv';
        element.click();
       await this.getShipping({ limit: this.limit });
    },
     selectAllCheckbox($event) {
        let columns = document.querySelectorAll('.rowCheckbox');
        this.tabId = [];
        if ($event.target.checked == true) {  
            columns.forEach(column => {
                column.checked = true;             
                this.tabId.push({id:column.id,source:column.getAttribute('seller'),trackingNumber:column.getAttribute('trackingNumber'),order:column.getAttribute('order'),upsel:column.getAttribute('upsel')||'false',status:column.getAttribute('status'),courier:column.getAttribute('courier'),orderobjet:column.getAttribute('orderobjet')});
            });
        } else {
            columns.forEach(column => {column.checked = false});
            this.tabId = [];
        }
    },
    getRowDetail(item, id) {
 
        // let rows = this.selectedRows;
        let itemInRow = this.tabId.filter(item => item.id === id);
        let isItemInRow = itemInRow.length > 0;
            
        if (isItemInRow) { 
            let pos = this.tabId.map(function (e) {return e.id;}).indexOf(id);
            this.tabId.splice(pos, 1);
        } else {
            this.tabId.push({id:id,trackingNumber:item.trackingNumber||'',source:item.seller.source?item.seller.source:'nosource',upsel:item.upsel||'false',order:item.order._id,status:item.status,courier:item.courier?item.courier._id||item.courier:'',orderobjet:JSON.stringify(item.orderObjet)});
        }
            
          if(this.tabId.length === 0){
              let columns = document.querySelectorAll('.checkallbbox');
              columns.forEach(column => {column.checked = false});
        }
    },
    emtyTabId(){
      let columns = document.querySelectorAll('.rowCheckbox');
      columns.forEach(column => {column.checked = false});
      this.tabId = [];
    },
    async getCouriers() {
      const filter = {
        type: "Courier",
        countries:await this.warhouseSelected,
        limit:10
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.couriers = res.content.results;
      else this.couriers = [];
    },
    searchCouriers: _.debounce(async function(search) {
      const filter = {
          type: "Courier",
          limit:10,
          countries:await this.warhouseSelected,
          searchByfullName:true,
          name: search,
        };  
        const res= await this.$server.find("users",filter);   
      if (res.content.results) this.couriers = res.content.results;
      else this.couriers = [];

       this.setDataSelect("courier", this.couriers, null);
    }, 500),
    searchSellers: _.debounce(async function(search,event) {
        const filter = {
            type: "Seller",
            limit:this.limit,
            countries:await this.warhouseSelected,
            name: search,
        };
        console.log('filter serch seller',filter)
        const res= await this.$server.find("users",filter);
        console.log('res serch seller',res)

        if (res.content.results) this.sellers = res.content.results;
        else this.sellers = [];
        this.setDataSelect("seller", this.sellers, null);
    }, 500),
    async getSellers() {
      const filter = {
          type: "Seller",
          countries:await this.warhouseSelected,
           limit:this.limit,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    async getProducts(filters) {
      
      const filter = {
        'details.warehouse':this.idWharhouse._id
      };
      //console.log(filter);
      const res = await this.$server.search("products", filter);
      //console.log(res);
      
      if (res.content.results) this.products = res.content.results;
      else this.products = [];
    },

    async getZones() {
        const res = await this.$server.search("zones",{ country:await this.warhouseSelected,limit: 1000});
        if (res.content.results) this.zones = res.content.results;
        else this.zones = [];
    },
    setDataSelect(model,data,splice){

      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
            if ( this.configList.filters.elements.champs[i].type === "select" && this.configList.filters.elements.champs[i].key === model) {
                if(!splice){
                  this.configList.filters.elements.champs[i].values=data;
                }else{
                  this.configList.filters.elements.champs.splice(i, 1);
                }               
            }
        }
    },
    setUserId(filters) {
      return (filters.seller = this.currentUser._id);
    },
    showProducts(index) {
      if (this.element !== this.shippings[index]) {
        this.element = this.shippings[index];
      } else this.element = null;
    },
    async CheckQteDefecteuse(item){
       this.CheckQteDefecteuseexist=false;
       if(item.type=='change' ){
             for (let i in item.order.details) {
                 const filters = {
                   product: item.order.details[i].product._id,
                   order: item.order._id,
                   shipping: item._id,
                 };
               const res = await this.$server.search("stockHistories", filters);
              
               if(typeof res.content.results !== 'undefined') {
                   item.order.details[i].product.qteChange=res.content.results[0].quantity;
                   this.CheckQteDefecteuseexist=true;
               }
             }
       }    
        
     }, 
    async CheckQteReturned(item){
      //console.log('item sip_____________',item)
       this.CheckQteReturnedexist=false;
       if(item.status=='return'){
             for (let i in item.order.details) {
                 const filters = {
                   product: item.order.details[i].product._id,
                   order: item.order._id,
                   shipping: item._id,
                 };
               const res = await this.$server.search("stockHistories", filters);
              console.log('res stockHistories_____________',res)
               if(typeof res.content.results !== 'undefined') {
                   item.order.details[i].product.qteChange=res.content.results[0].quantity;
                   this.CheckQteReturnedexist=true;
               }
             }
       }    
     },
    async getPhoneDelevry(data){
       for (const element of  data) {
                element.date=this.$moment(element.date).format('yyyy-MM-DD');
                element.order_id=element.order.id;  
                element.source=element.order.source;  
                element.autorise_colis=false;  
                let dataWhrahouseSetting=null;
                if(element.seller.settings && element.seller.settings.length>0) dataWhrahouseSetting=this.getElementInArray(element.seller.settings,this.idWharhouse._id);
                if(dataWhrahouseSetting && dataWhrahouseSetting.shipping) element.phone=this.phoneED;
                else element.phone=element.seller.phone; 
                if(dataWhrahouseSetting) element.autorise_colis=dataWhrahouseSetting.open_colis;  
          }
    }, 
    async getShipping(filters) {
      //filters=this.setUserId();
      if(await this.warhouseSelected == 'MA') this.configList=configMA.list;
      else this.configList=config.list;
      this.loading = true;
      if (this.currentUser.type == "Courier") {
        if(!filters.search)  filters.status = 'StatusCourier'; 
        else {
          if(typeof filters.status === "undefined") filters.status = 'StatusCourier'; 
           delete filters.search
          };
      }
      

      if (this.currentUser.type != "Courier") delete filters.search
       filters.country=await this.warhouseSelected;
      if(this.sous_idWharhouse && this.sous_warehouses.length>0 && this.sous_idWharhouse!='all') filters['orderObjet.details.warehouseParent']=this.sous_idWharhouse;
      const res = await this.$server.search("shippings", filters);
      this.loading = false;
      this.CourierInfo.total=this.CourierInfo.count=0;
      if (res && res.content.results) {
        await this.getPhoneDelevry(res.content.results);   
        this.shippings = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;

        if(this.isFilterCourier){
              this.CourierInfo.count =res.content.results.length;
               for (const element of  res.content.results) {
                    this.CourierInfo.total +=element.order.totalPrice;
               }
      }
      } else {this.shippings = [];
          this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
      

    },
    async getUserByid(id) {
       const filters = {
         _id:id
       }
       const res= await this.$server.find("users",filters);
        if (res.content) {
          return res.content[0].email;
         };
    },
    async getShippingStockManager() {
       const filters = {
          status: "to prepare",
          dateShipp:this.$moment(Date.now()).format('yyyy-MM-DD'),
          country:await this.warhouseSelected
      };
      const res = await this.$server.search("shippings", filters);
     // console.log('shipping information',res.content)
      if (res.content.results) {
        this.shippingsStockManager = res.content.results;
      } else this.shippingsStockManager = [];
    },
     async getShippingLivreur() {
       const filters = {
          status: "prepared",
          dateShipp:this.$moment(Date.now()).format('yyyy-MM-DD'),
          country:await this.warhouseSelected
      };
      const res = await this.$server.search("shippings", filters);
      if (res.content.results) {
        this.shippingsLivreur = res.content.results;
      } else this.shippingsLivreur = [];
    },

    async refresh() {
      this.emtyTabId();
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.$f.objectToQueryString(this.filters)
      if(this.filters.name) await this.search(this.filters.name);
      else await this.getShipping(this.filters);
      // this.getShippingStockManager();
      // this.getShippingLivreur();
      this.isFilterCourier=false;
      if(this.filters['page'] && this.filters['page']>1 && this.shippings.length==0){
          this.filters['page']= --this.filters['page'];
          this.$f.objectToQueryString(this.filters)
          await this.getShipping(this.filters);
       }
    },

    /*********************************************************************************/
    async reset(data) {
      this.emtyTabId();
       let lmitt=this.limit;
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      if(this.filters['limit']) lmitt=this.filters['limit'];
      this.filters['page']=1;
      let filterO={limit:lmitt,country:await this.warhouseSelected};
      if(this.sous_idWharhouse && this.sous_warehouses.length>0 && this.sous_idWharhouse!='all') filterO['orderObjet.details.warehouseParent']=this.sous_idWharhouse;
      this.$f.objectToQueryString(filterO)

      this.isFilterCourier=false;
      await this.getShipping({limit:lmitt});
      //this.getShippingStockManager();
      //this.getShippingLivreur();
    },
    async search(data) {
      this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
       let limitt=this.limit
       if(this.filters['limit']) limitt=this.filters['limit'];

       const search = {name:data.trim(),limit:parseInt(limitt)}
      
      if (this.currentUser.type == "Courier") {
        search.courier = this.currentUser._id;
         search.status = 'StatusCourier'; 
        //search.status = 'prepared';
      }
      search.country=await this.warhouseSelected;
      if(this.sous_idWharhouse && this.sous_warehouses.length>0 && this.sous_idWharhouse!='all') search['orderObjet.details.warehouseParent']=this.sous_idWharhouse;
      this.$f.objectToQueryString(search)
      this.loading = true;
      const res = await this.$server.find("shippings", search);
      this.loading = false;
      if (res.content.results) {
        await this.getPhoneDelevry(res.content.results);  
        this.shippings = res.content.results;
        this.shippings.total = res.content.total;
        this.shippings.lastpage = res.content.last_page;
        this.shippings.per_page = res.content.per_page;
        this.shippings.currentpage = res.content.current_page;
      } else{this.shippings = []; this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}} 
    },
    async filtrer(data) {
      console.log('data__________________',data)
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       delete  this.filters.page;
       delete  this.filters.name;

       if(!this.filters['limit']) this.filters['limit']=this.limit;

      if(data.status) this.filters.status=data.status;else delete this.filters.status;
      if(data.date) this.filters.date=data.date;else delete this.filters.date;
      if(data.Range_date){this.filters.Range_date=data.Range_date.map( el => this.$moment(el).format('yyyy-MM-DD'));}else delete this.filters.Range_date;
      if(data.from && data.to){delete this.filters.date;this.filters.from=data.from;this.filters.to=data.to;}else {delete this.filters.from; delete this.filters.to;}
      if(data.city) this.filters.city=data.city;else delete this.filters.city;
       this.filters.IsFilter=true;
       this.paginate.currentpage=1;
      if(data.seller) this.filters.seller=data.seller._id;else delete this.filters.seller;
      if(data.product)  this.filters.product=data.product._id;else delete this.filters.product;
      if(this.sous_idWharhouse && this.sous_warehouses.length>0 && this.sous_idWharhouse!='all') this.filters['orderObjet.details.warehouseParent']=this.sous_idWharhouse;else delete this.filters['orderObjet.details.warehouseParent'];
    
      if (this.currentUser.type == "Courier") {this.filters.search=true;}
      if(data.zone)  this.filters.zone=data.zone._id;else delete this.filters.zone;

      
      if (data.courier) {
         if (this.currentUser.type != "Seller"){this.isFilterCourier=true;this.CourierInfo.courier=data.courier.fullName||data.courier.email}
          this.filters.courier = data.courier._id;
      }else {delete this.filters.courier;this.CourierInfo.courier=null;}

      if(data.status && (data.status=="paid" || data.status=="delivered")) this.isFilterCourier=true;
      if((!data.status || (data.status && data.status!="paid" && data.status!="delivered")) && !data.courier) this.isFilterCourier=false;
      this.$f.objectToQueryString(this.filters)
      await this.getShipping(this.filters);
      //console.log('this shipp data  => ',this.shippings)

    },
    /****************************************************************************/
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
     // console.log(this.currentUser);
    },
    /***************************************************************************************/
    async paginatation(direction){
      var page=0;
      if(direction == "next"){
         page=++this.paginate.currentpage;
      }else{
         page=--this.paginate.currentpage;
      }
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.filters['page']=page;
      this.$f.objectToQueryString(this.filters)

      this.getShipping(this.filters);
    },
    async generateListPdf() {
      //console.log('shippppp_____________________________',this.shippings)
          // const doc = new jsPDF('p', 'pt');
          //    doc.setFontSize(14);
          //   doc.setFont("times","normal",400);

          //   let items=this.shippings;
          //   // if (this.currentUser.type == "StockManager") items=this.shippingsStockManager;
          //   // if (this.currentUser.type == "Courier") items=this.shippingsLivreur;

          //   doc.text(this.$t('shippings_list')+'   '+this.$moment(items[0].date).format('yyyy-MM-DD'), 30, 30);
          //   doc.text(`By ${this.currentUser.email} `, 40, 50);
          //   let data=[];
          //   let i = 1;
          //   items.forEach(el =>{let obj = [
          //       i,
          //       el.seller.fullName||el.seller.email,
          //       el.address,
          //       this.$moment(el.date).format('yyyy-MM-DD'),
          //       el.trackingNumber,
          //       el.order.details.length+" "+this.$t('products'),
          //       el.order.customer.city,
          //   ];
          //   i++;
          //   data.push(obj);
          //   doc.autoTable({ 
          //   headerStyles: {fillColor: '#ffffff', fontStyle: 'bold',textColor: '#000',lineWidth: 1,lineColor: '#000' },
          //   theme: 'grid',
          //   head: [['#',this.$t('seller'), this.$t('shipping_address'),this.$t('shipping_date'),this.$t('traking_number'),this.$t('order'),this.$t('city')]],
          //   margin: { top: 90 },
          //   body: data,
          //   })
          //   data=[];

          //   //order
          //   let j = 1;
          //   let dataord =[];
          //   el.order.details.forEach(ell =>{let obj = [
          //       j,
          //       ell.product.code,
          //       ell.product.name,
          //       ell.unitPrice,
          //       ell.quantity,
          //   ];
          //   j++;
          //   dataord.push(obj);
          //   });
          //   doc.setFontSize(10);
            
          //   doc.text(this.$t('order')+":          "+this.$t('order_id')+" : "+el.order.id+"               Total Price: "+el.order.totalPrice+"               Store: "+el.order.source, 40, doc.autoTable.previous.finalY + 13);
          //   doc.text("_______", 40, doc.autoTable.previous.finalY + 15);
          //   doc.autoTable({ 
          //   styles: { halign: 'center' },
          //   headerStyles: {fillColor: '#ffffff', fontStyle: 'bold',textColor: '#000',lineWidth: 1,lineColor: '#000' },
          //   theme: 'grid',
          //   head: [['#', 'Code', 'Name','Unit Price','Quantity']],
          //   margin: { top: 30 },
          //   body: dataord,
          //   })
          //   //ed order
          //   });

  
          //   doc.save('Shipping_List.pdf')

            console.log('this.tabId--------------------',this.tabId)

            this.tabIdList=[];
            for(let i in this.tabId){
                this.tabIdList.push({id : this.tabId[i].id , status:this.tabId[i].status})
            }
            if(this.tabIdList.length==0){
                alert(`No Shipping found !`, "warning");
                return false;
            }
            const idsNotprepared=this.tabIdList.filter(function(item) { return item.status === "to prepare"; });
            if(idsNotprepared.length>0) {
                alert(`Print List est seulement autorisé pour les shoppings non (to prepare) !`, "warning");
                return false;
            }
            const filterTocourier=this.$f.QueryStringToobject();
            let Courier=null
            if(filterTocourier.courier){
              const userC = await this.$server.get("users", {id: filterTocourier.courier,});
              Courier=userC.content;
            }
            console.log('this.tabIdList----------',this.tabIdList)
            var tableheader = '<div class="w-full" style="page-break-after: avoid; page-break-before: avoid;">';
              tableheader += '<table style="margin:10px;margin-top:0;table-layout: fixed; width: 100%;"><tbody class="bg-white">';
              tableheader += '<tr class="w-full"><td class="align-top font-bold"  style="font-size: 0.70rem;line-height: 1rem;width: 50% !important;border: none !important;"><span>Nombre de commande : '+this.tabIdList.length+'</span><br><span>Date : '+this.$moment(this.tabId[0].date).format('DD-MM-yyyy')+'</span>';
              if(Courier)  tableheader += '<br><span>Livreur : '+Courier.fullName+'</span>';
              tableheader += '</td>';
              tableheader += '<td class="align-top text-right" style="width: 50% !important;border: none !important;"><img style="width:40%;" alt="..." class="rounded-full align-middle border-none" src="'+this.image+'"/></td></tr>';
              tableheader += '</tbody></table>';
              tableheader += '<table class="w-full">';
              tableheader += '<thead class="justify-between">';
              tableheader += '<tr class="border border-black">';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;" class="px-2 text-center leading-4 text-black tracking-wider"> <span class="text-black">'+this.$t('ORDER ID')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">Traking</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('fullName')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('Phone')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('city')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('Seller')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('Quantity')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('order_products')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('price')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">'+this.$t('shipping_address')+'</span></th>';
              tableheader += '<th style="font-size: 0.60rem;line-height: 1.20rem;"  class="px-2 text-center text-xs leading-4 text-black tracking-wider"><span class="text-black">Justification</span></th>';
              tableheader += '</tr></thead><tbody class="bg-white w-full">';

             var  tablefooter ='</tbody></table></div>';
              
              var printContents="";
              printContents +=tableheader;

              for(let i in this.tabIdList){
                 let modalPr_id = "ModalprintList_" + this.tabIdList[i].id;
                 printContents += document.getElementById(modalPr_id).innerHTML;
              }

              printContents +=tablefooter;
              
              // console.log(window.location.pathname.split( '/' ))
                  var mywindow = window.open('', 'PRINT', 'height=400,width=600');
                  mywindow.document.write('<html lang="en"><head><meta charset="utf-8"><title>DELIVRY NOTE</title>');
                  mywindow.document.write('<style type="text/css">.m-2{margin:4mm}.barcode-img img{width: 150px;height:20px}.overflow-visible{white-space: initial;}.trakingn img{width: 50%;}.mb-0{margin-bottom: 0px;}.w-1/4{width: 25%;}.w-3/4{width: 75%;}.m-0{margin:0}.pt-0{padding-top: 0px;}.mt-0{margin-top: 0px;}.py-1{padding-top: 0.25rem;padding-bottom: 0.25rem;}.px-3{padding-left: 0.75rem;padding-right: 0.75rem;}.w-2/4{width: 50%;}.mt-1{margin-top: 0.25rem;}.align-top{vertical-align: top;}p{margin:0}table{border-collapse: collapse;}table th, table td {border:1px solid #000;}.text-right{text-align:right}.flex-1{flex:1 1 0%}.w-40{width:10rem}.font-medium{font-weight:500}.py-1{padding-top:.25rem;padding-bottom:.25rem}.inline-block{display:inline-block}.mb-3{margin-bottom:.75rem}.py-2{padding-top:.5rem;padding-bottom:.5rem}.ml-auto{margin-left:auto}.border-black{border: 1px solid black;}.border{border-width:1px}.whitespace-no-wrap{white-space:nowrap}.text-center{text-align:center}.w-1/4{width: 25%}.text-left{text-align:left}.px-2{padding-left:.5rem;padding-right:.5rem}.min-w-full{min-width:100%}.mb-5{margin-bottom:1.25rem}.px-1{padding-left:.25rem;padding-right:.25rem}.mx-auto{margin-left:auto;margin-right:auto}body{font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";font-size:1em}.justify-between{justify-content:space-between}.w-full{width:100%}.block{display:block}.flex{display:flex}.uppercase{text-transform: uppercase;}.mb-2{margin-bottom: 0.5rem;}.mb-3{margin-bottom: 0.75rem;}.font-bold{font-weight: 700;}.font-semibold{font-weight:600}.text-xl{font-size: 1.25rem;line-height: 1.75rem;}.size-m{font-size: 12px;font-weight: 500;}@media print {@page {size:  auto; margin: 0mm;}}</style>');
                  mywindow.document.write('</head><body class="border border-black" style="margin:10mm;padding:5mm">');
                  mywindow.document.write(printContents);
                  mywindow.document.write('</body></html>');
                  mywindow.document.close(); // necessary for IE >= 10
                  mywindow.focus(); // necessary for IE >= 10*/
                  setTimeout(function () {
                      mywindow.print();
                      mywindow.close();
                  }, 500);
                  return false;
        },
  },
};
</script>

<style>
</style>




